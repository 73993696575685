<template>

  <div class="flex flex-col mt-4 md:mt-0 items-start md:items-end">
    <input
      class="text-left md:text-right text-gray-400 rounded-lg uppercase text-xs w-full md:w-300px p-1 mt-2 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="invoiceDateLabel"
      @input="updateValue('invoiceDateLabel', $event.target.value)"
    />
    <div class="relative mt-1 w-full md:w-auto">
      <div class="relative">

        <input
          class="text-left md:text-right rounded-lg w-full md:w-300px p-1 pr-10 outline-none transition-all duration-200 ease-in-out border"
          :class="invoiceDatePickerVisible ? 'border-il-purple' : 'border-gray-200 focus:border-il-purple'"
          type="text"
          :value='invoiceDate'
          @input="updateValue('invoiceDate', $event.target.value)"
          :style="{ color: accentTextColor }"
        />
        <div
          @click="showInvoiceDatePicker"
          class="absolute right-0 top-0 p-1 cursor-pointer text-white bg-il-purple rounded-r-lg border border-purple"
        >
          <i class="far fa-calendar-alt"></i>
        </div>
      </div>
      <div class="absolute top-8 z-10 right-0">
        <v-calendar
          v-if="invoiceDatePickerVisible"
          v-click-away="hideInvoiceDatePicker"
          :attributes="invoiceDateAttributes"
          @dayclick="updateDate('invoiceDate', $event)"
        />
      </div>
    </div>

    <input
      class="text-left md:text-right text-gray-400 rounded-lg uppercase text-xs w-full md:w-300px p-1 mt-2 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="paymentDateLabel"
      @input="updateValue('paymentDateLabel', $event.target.value)"
    />
    <div class="relative mt-1 w-full md:w-auto">
      <div class="relative">
        <input
          class="text-left md:text-right rounded-lg w-full md:w-300px p-1 pr-10 outline-none transition-all duration-200 ease-in-out border"
          :class="paymentDatePickerVisible ? 'border-il-purple' : 'border-gray-200 focus:border-il-purple'"
          type="text"
          :value='paymentDate'
          @input="updateValue('paymentDate', $event.target.value)"
          :style="{ color: accentTextColor }"
        />
        <div
          @click="showPaymentDatePicker"
          class="absolute right-0 top-0 p-1 cursor-pointer text-white bg-il-purple rounded-r-lg border border-purple"
        >
          <i class="far fa-calendar-alt"></i>
        </div>
      </div>
      <div class="absolute top-8 z-10 right-0">
        <v-calendar
          v-if="paymentDatePickerVisible"
          v-click-away="hidePaymentDatePicker"
          :attributes="paymentDateAttributes"
          @dayclick="updateDate('paymentDate', $event)"
        />
      </div>
    </div>

    <input
      class="text-left md:text-right text-gray-400 rounded-lg uppercase text-xs w-full md:w-300px p-1 mt-2 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="paymentTermsLabel"
      @input="updateValue('paymentTermsLabel', $event.target.value)"
    />
    <input
      class="mt-1 text-left md:text-right rounded-lg w-full md:w-300px p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="paymentTerms"
      @input="updateValue('paymentTerms', $event.target.value)"
    />

    <div class="mt-4 md:mt-10">
      <input
        class="p-2 text-white rounded-l-lg uppercase w-150px outline-none"
        :style="{ backgroundColor: accentColor }"
        type="text"
        :value="balanceDueLabel"
        @input="updateValue('balanceDueLabel', $event.target.value)"
      />
      <input
        class="-ml-1 p-2 pl-0 text-white text-left md:text-right rounded-r-lg w-150px outline-none cursor-default"
        :style="{ backgroundColor: accentColor }"
        type="text"
        :value="formattedDueAmount"
        readonly
      />
    </div>

    <div class="text-xs text-gray-400 my-2">
      <div class="text-left md:text-right text-il-purple">
        <input
          class="text-left md:text-right uppercase rounded-lg w-300px p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
          type="text"
          :value="currencyLabel"
          @input="updateValue('currencyLabel', $event.target.value)"
          :style="{ color: accentTextColor }"
        />
      </div>
      <div class="text-left md:text-right">
        {{ currency.currency }} ({{ currency.code }})
      </div>
    </div>

  </div>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  import { format } from 'date-fns';
  import { directive } from "vue3-click-away";

  export default {
    props: [
      'accentColor',
      'accentTextColor',
      'currency',
      'invoiceDateLabel',
      'invoiceDate',
      'paymentDateLabel',
      'paymentDate',
      'paymentTermsLabel',
      'paymentTerms',
      'balanceDueLabel',
      'currencyLabel',
      'total',
      'paid',
      'formattedDueAmount'
    ],
    emits: [
      'update:invoiceDateLabel',
      'update:invoiceDate',
      'update:paymentDateLabel',
      'update:paymentDate',
      'update:paymentTermsLabel',
      'update:paymentTerms',
      'update:balanceDueLabel',
      'update:currencyLabel'
    ],
    directives: {
      ClickAway: directive
    },
    components: {

    },
    data() {
      return {
        invoiceDatePickerVisible: false,
        paymentDatePickerVisible: false,
        invoiceDateAttributes: [
          {
            "dates": new Date(),
            "highlight": {
              "class": "bg-il-purple",
              "contentClass": "text-white"
            },
          }
        ],
        paymentDateAttributes: [
          {
            "dates": new Date(),
            "highlight": {
              "class": "bg-il-purple",
              "contentClass": "text-white"
            },
          }
        ],
      }
    },
    methods: {
      ...mapMutations([

      ]),
      showInvoiceDatePicker() {
        this.invoiceDatePickerVisible = true;
      },
      hideInvoiceDatePicker() {
        this.invoiceDatePickerVisible = false;
      },
      showPaymentDatePicker() {
        this.paymentDatePickerVisible = true;
      },
      hidePaymentDatePicker() {
        this.paymentDatePickerVisible = false;
      },
      updateValue(prop,value) {
        this.$emit('update:'+prop, value);
      },
      updateDate(prop,day) {
        if(prop == 'invoiceDate') {
          this.invoiceDateAttributes = [
            {
              "dates": day.date,
              "highlight": {
                "class": "bg-il-purple",
                "contentClass": "text-white"
              },
            }
          ];
          this.hideInvoiceDatePicker();
        } else {
          this.paymentDateAttributes = [
            {
              "dates": day.date,
              "highlight": {
                "class": "bg-il-purple",
                "contentClass": "text-white"
              },
            }
          ];
          this.hidePaymentDatePicker();
        }
        var timestamp = Date.parse(day.date);
        this.$emit('update:'+prop, format(timestamp, 'do MMM, yyyy'));
      }
    },
    computed: {
      ...mapGetters([

      ])
    }
  }
</script>