<template>

  <div class="w-full">
    <input
      class="text-sm text-right text-gray-400 rounded-l-lg uppercase w-7/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="subtotalLabel"
      @input="updateValue('subtotalLabel', $event.target)"
    />
    <input
      class="bg-gray-50 text-sm cursor-default text-right rounded-r-lg w-5/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200"
      type="text"
      readonly
      :value="subtotal.toFixed(2)"
    />
  </div>

  <div class="flex w-full relative mt-1">
    <input
      class="text-sm text-right text-gray-400 rounded-l-lg uppercase w-7/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="discountLabel"
      @input="updateValue('discountLabel', $event.target)"
    />
    <input
      class="text-sm text-right w-5/12 p-1 pr-12 rounded-r-lg outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="number"
      :value="discount"
      @input="updateValue('discount', $event.target)"
    />
    <span
      class="absolute right-0 top-0 cursor-pointer rounded-r-lg input-symbol w-45px text-sm text-white inline-block text-center bg-il-purple font-bold"
      @click="toggleDiscountType"
    >
      {{ discountType == '%' ? '%' : currency.symbol }}
    </span>
  </div>

  <div class="flex w-full relative mt-1">
    <input
      class="text-sm text-right text-gray-400 rounded-l-lg uppercase w-7/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="taxLabel"
      @input="updateValue('taxLabel', $event.target)"
    />
    <input
      class="text-sm text-right w-5/12 p-1 pr-12 rounded-r-lg outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="number"
      :value="tax"
      @input="updateValue('tax', $event.target)"
    />
    <span
      class="absolute right-0 top-0 cursor-pointer rounded-r-lg input-symbol w-45px text-sm text-white inline-block text-center bg-il-purple font-bold"
      @click="toggleTaxType"
    >
      {{ taxType == '%' ? '%' : currency.symbol }}
    </span>
  </div>

  <div class="mt-1">
    <input
      class="text-sm text-right text-gray-400 rounded-l-lg uppercase w-7/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="shippingCostLabel"
      @input="updateValue('shippingCostLabel', $event.target)"
    />
    <input
      class="text-sm text-right rounded-r-lg w-5/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="number"
      :value="shippingCost"
      @input="updateValue('shippingCost', $event.target)"
    />
  </div>

  <div class="mt-1">
    <input
      class="font-bold text-right text-gray-400 rounded-l-lg uppercase w-7/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="amountPaidLabel"
      @input="updateValue('amountPaidLabel', $event.target)"
      :style="{ color: accentTextColor }"
    />
    <input
      class="font-bold text-right rounded-r-lg w-5/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="number"
      :value="amountPaid"
      @input="updateValue('amountPaid', $event.target)"
      :style="{ color: accentTextColor }"
    />
  </div>

  <div class="mt-1">
    <input
      class="font-bold text-right text-gray-400 rounded-l-lg uppercase w-7/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
      type="text"
      :value="totalLabel"
      @input="updateValue('totalLabel', $event.target)"
      :style="{ color: accentTextColor }"
    />
    <input
      class="bg-gray-50 cursor-default font-bold text-right rounded-r-lg w-5/12 p-1 outline-none transition-all duration-200 ease-in-out border border-gray-200"
      type="number"
      :value="total"
      @input="updateValue('total', $event.target)"
      :style="{ color: accentTextColor }"
      readonly
    />
  </div>

</template>


<script>
  import { format } from 'date-fns';
  import { mapGetters, mapMutations } from 'vuex';
  import lodash from 'lodash';

  export default {
    props: [
      'accentTextColor',
      'currency',
      'accentColor',
      'subtotalLabel',
      'subtotal',
      'discountLabel',
      'discount',
      'discountType',
      'taxLabel',
      'tax',
      'taxType',
      'shippingCostLabel',
      'shippingCost',
      'amountPaidLabel',
      'amountPaid',
      'totalLabel',
      'total'
    ],
    emits: [
      'update:subtotalLabel',
      'update:discountLabel',
      'update:discount',
      'update:discountType',
      'update:taxLabel',
      'update:tax',
      'update:taxType',
      'update:shippingCostLabel',
      'update:shippingCost',
      'update:amountPaidLabel',
      'update:amountPaid',
      'update:totalLabel',
      'update:total'
    ],
    created() {
      // this.discountType = this.getCurrencyX.symbol;
      // this.taxType = this.getCurrencyX.symbol;
    },
    components: {

    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      toggleDiscountType() {
        if(this.discountType == '%') {
          this.$emit('update:discountType', this.currency.symbol);
        } else {
          this.$emit('update:discountType', '%');
        }
      },
      toggleTaxType() {
        if(this.taxType == '%') {
          this.$emit('update:taxType', this.currency.symbol);
        } else {
          this.$emit('update:taxType', '%');
        }
      },
      updateValue(prop,target) {
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ]),
      // discountCurrToPerc() {
      //   return (this.discount*100) / this.subtotal;
      // },
      // discountPercToCurr() {
      //   return (this.discount * this.subtotal) / 100;
      // },

      // taxCurrToPerc() {
      //   if(this.discountType == '%') {
      //     return (this.tax*100) / (this.subtotal - this.discountPercToCurr);
      //   }
      //   return (this.tax*100) / (this.subtotal - this.discount);
      // },
      // taxPercToCurr() {
      //   if(this.discountType == '%') {
      //     return (this.tax * (this.subtotal - this.discountPercToCurr)) / 100;
      //   }
      //   return (this.tax * (this.subtotal - this.discount)) / 100;
      // },

      // total() {
      //   if(this.subtotal <= 0) {
      //     return "N/A";
      //   }

      //   let discount = 0;
      //   if(this.discount !== '' && this.discount >= 0 && !isNaN(this.discount)) {
      //     if(this.discountType == '%') {
      //       discount = (this.discount * this.subtotal) / 100;
      //     } else {
      //       discount = this.discount;
      //     }
      //   }
      //   else {
      //     return "N/A";
      //   }

      //   let tax = 0;
      //   if(this.tax !== '' && this.tax >= 0 && !isNaN(this.tax)) {
      //     if(this.taxType == '%') {
      //       tax = (this.tax * (this.subtotal - discount)) / 100;
      //     } else {
      //       tax = this.tax;
      //     }
      //   }
      //   else {
      //     return "N/A";
      //   }

      //   let shipping = 0;
      //   if(this.shipping !== '' && this.shipping >= 0 && !isNaN(this.shipping)) {
      //     shipping = parseFloat(this.shipping);
      //   }
      //   else {
      //     return "N/A";
      //   }

      //   let total = parseFloat(this.subtotal) - parseFloat(discount) + parseFloat(tax) + parseFloat(shipping); // - parseFloat(this.paid);
      //   this.setTotalX(parseFloat(total).toFixed(2));
      //   return parseFloat(total.toFixed(2));
      // },
    }
  }
</script>

<style>
  .input-symbol {
    padding: 5px 0px;
  }
</style>