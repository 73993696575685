<template>

  <!-- ROWS FOR MOBILE -->
  <div
    v-for="(row,index) in itemRows"
    :key="index"
    class="flex flex-col md:hidden mt-2 relative"
  >
    <div
      v-if="itemRows.length > 1"
      class="md:hidden text-xl absolute -top-3 -right-1 p-2 pr-1 text-red-600 cursor-pointer"
      @click="removeRow(index)"
    >
      <i class="fas fa-minus-circle"></i>
    </div>

    <div class="flex flex-col">
      <div class="flex">
        <textarea
          class="w-5/12 rounded-tl-lg outline-none font-bold text-white p-2 resize-none overflow-hidden"
          rows='1'
          data-min-rows='1'
          data-height-step='24'
          :value="itemLabel"
          @input="updateValue('itemLabel', $event.target, 24)"
          :style="{ backgroundColor: accentColor }"
        >
        </textarea>
        <textarea
          class="w-7/12 outline-none p-2 resize-none overflow-hidden border border-gray-300 rounded-tr-lg"
          rows='1'
          data-min-rows='1'
          data-height-step='24'
          :value="row.item"
          @input="updateRowItem('itemRows', $event.target, 24, index,'item')"
        >
        </textarea>
      </div>

      <div class="flex">
        <textarea
          class="w-5/12 outline-none font-bold text-white p-2 resize-none overflow-hidden"
          rows='1'
          data-min-rows='1'
          data-height-step='24'
          :value="quantityLabel"
          @input="updateValue('quantityLabel', $event.target, 24)"
          :style="{ backgroundColor: accentColor }"
        >
        </textarea>
        <div class="relative w-7/12 border-r border-b border-gray-300 flex items-center">
          <input
            class="w-full outline-none p-2"
            :class="rowValidation[index].quantity ? '' : 'bg-red-100'"
            :type="row.isTime ? 'text' : 'number'"
            :placeholder="row.isTime ? 'hh:mm' : '1'"
            :value="row.quantity"
            @input="updateRowItem('itemRows', $event.target, 24, index,'quantity')"
          />
          <div
            v-if="!row.isTime"
            class="absolute top-0 right-1 p-2 pr-1 text-il-purple-faded hover:text-il-purple cursor-pointer"
            @click="quantityTypeSwitch(index)"
          >
            <i class="fas fa-weight-hanging"></i>
          </div>
          <div
            v-else
            class="absolute top-0 right-1 p-2 pr-1 text-il-purple-faded hover:text-il-purple cursor-pointer"
            @click="quantityTypeSwitch(index)"
          >
            <i class="fas fa-clock"></i>
          </div>
        </div>
      </div>

      <div class="flex">
        <textarea
          class="w-5/12 outline-none font-bold text-white p-2 resize-none overflow-hidden"
          rows='1'
          data-min-rows='1'
          data-height-step='24'
          :value="rateLabel"
          @input="updateValue('rateLabel', $event.target, 24)"
          :style="{ backgroundColor: accentColor }"
        >
        </textarea>
        <div
          class="relative w-7/12 border-r border-b border-gray-300 flex items-center"
        >
          <div v-if="!currency.after_nr" class="pl-2 pr-0">
            {{ currency.symbol }}
          </div>
          <input
            class="w-full outline-none p-2 pl-1"
            :class="rowValidation[index].rate ? '' : 'bg-red-100'"
            type="number"
            :value="row.rate"
            @input="updateRowItem('itemRows', $event.target, 24, index,'rate')"
          />
          <div
            class="absolute top-0 right-0 bg-white flex items-center p-2 px-0 h-full"
          >
            <div
              v-if="currency.after_nr"
              class="pr-1"
            >
              {{ currency.symbol }}
            </div>
            <input
              v-if="row.isTime"
              type="text"
              class="hover:bg-gray-100 transition-all duration-200 ease-in-out w-12 overflow-hidden h-6 outline-none"
              @input="updateRowItem('itemRows', $event.target, 24, index,'perHourLabel')"
              :value="row.perHourLabel"
            />
          </div>
        </div>
      </div>

      <div class="flex">
        <textarea
          class="w-5/12 rounded-bl-lg outline-none font-bold text-white p-2 resize-none overflow-hidden"
          rows='1'
          data-min-rows='1'
          data-height-step='24'
          :value="amountLabel"
          @input="updateValue('amountLabel', $event.target, 24)"
          :style="{ backgroundColor: accentColor }"
        >
        </textarea>
        <div
          class="w-7/12 flex items-center border-r border-b border-gray-300 rounded-br-lg bg-gray-50"
        >
          <div
            v-if="!currency.after_nr"
            class="pl-2 pr-0 w-2/12"
          >
            {{ currency.symbol }}
          </div>
          <input
            class="outline-none p-2 cursor-default w-8/12 bg-gray-50"
            type="text"
            readonly
            :value="row.amount"
          />
          <div
            v-if="currency.after_nr"
            class="w-4/12"
          >
            {{ currency.symbol }}
          </div>
        </div>
      </div>

    </div>
  </div>
  <!-- END ROWS FOR MOBILE -->

  <div class="hidden md:flex md:flex-col">

    <div class="flex">
      <textarea
        class="w-6/12 rounded-tl-lg outline-none font-bold text-white p-2 resize-none overflow-hidden"
        rows='1'
        data-min-rows='1'
        data-height-step='24'
        :value="itemLabel"
        @input="updateValue('itemLabel', $event.target, 24)"
        :style="{ backgroundColor: accentColor }"
      >
      </textarea>
      <textarea
        class="w-2/12 outline-none font-bold text-white p-2 resize-none overflow-hidden"
        rows='1'
        data-min-rows='1'
        data-height-step='24'
        :value="quantityLabel"
        @input="updateValue('quantityLabel', $event.target, 24)"
        :style="{ backgroundColor: accentColor }"
      >
      </textarea>
      <textarea
        class="w-2/12 outline-none font-bold text-white p-2 resize-none overflow-hidden"
        rows='1'
        data-min-rows='1'
        data-height-step='24'
        :value="rateLabel"
        @input="updateValue('rateLabel', $event.target, 24)"
        :style="{ backgroundColor: accentColor }"
      >
      </textarea>
      <textarea
        class="w-2/12 rounded-tr-lg outline-none font-bold text-white p-2 resize-none overflow-hidden"
        rows='1'
        data-min-rows='1'
        data-height-step='24'
        :value="amountLabel"
        @input="updateValue('amountLabel', $event.target, 24)"
        :style="{ backgroundColor: accentColor }"
      >
      </textarea>
    </div>
  </div>

  <div
    v-for="(row,index) in itemRows"
    class="hidden md:flex w-full relative border-l border-r border-b border-gray-300"
    :class="{ 'rounded-b-lg' : index == itemRows.length-1 }"
    :key="index"
  >
    <textarea
      class="w-6/12 outline-none p-2 border-r border-gray-300 overflow-hidden resize-none"
      :class="{ 'rounded-bl-lg' : index == itemRows.length-1 }"
      rows='1'
      data-min-rows='1'
      data-height-step='25'
      :value="row.item"
      @input="updateRowItem('itemRows', $event.target, 25, index,'item')"
    >
    </textarea>

    <div class="relative w-2/12 border-r border-gray-300 flex items-center">
      <input
        class="w-full outline-none p-2"
        :class="rowValidation[index].quantity ? '' : 'bg-red-100'"
        :type="row.isTime ? 'text' : 'number'"
        :placeholder="row.isTime ? 'hh:mm' : '1'"
        :value="row.quantity"
        @input="updateRowItem('itemRows', $event.target, 25, index,'quantity')"
      />
      <div
        v-if="!row.isTime"
        class="absolute top-0 right-1 p-2 pr-1 text-il-purple-faded hover:text-il-purple cursor-pointer"
        @click="quantityTypeSwitch(index)"
      >
        <i class="fas fa-weight-hanging"></i>
      </div>
      <div
        v-else
        class="absolute top-0 right-1 p-2 pr-1 text-il-purple-faded hover:text-il-purple cursor-pointer"
        @click="quantityTypeSwitch(index)"
      >
        <i class="fas fa-clock"></i>
      </div>
    </div>

    <div
      class="relative w-2/12 border-r border-gray-300 flex items-center"
    >
      <div v-if="!currency.after_nr" class="pl-2 pr-0">
        {{ currency.symbol }}
      </div>
      <input
        class="w-full outline-none p-2 pl-1"
        :class="rowValidation[index].rate ? '' : 'bg-red-100'"
        type="number"
        :value="row.rate"
        @input="updateRowItem('itemRows', $event.target, 25, index,'rate')"
      />
      <div
        class="absolute top-0 right-0 bg-white flex items-center p-2 px-0 h-full"
      >
        <div
          v-if="currency.after_nr"
          class="pr-1"
        >
          {{ currency.symbol }}
        </div>
        <input
          v-if="row.isTime"
          type="text"
          class="hover:bg-gray-100 transition-all duration-200 ease-in-out w-12 overflow-hidden h-6 outline-none"
          @input="updateRowItem('itemRows', $event.target, 25, index,'perHourLabel')"
          :value="row.perHourLabel"
        />
      </div>
    </div>

    <div
      class="w-2/12 flex items-center relative bg-gray-50"
    >
      <div
        v-if="!currency.after_nr"
        class="pl-2 pr-0 w-2/12"
      >
        {{ currency.symbol }}
      </div>
      <input
        class="outline-none p-2 cursor-default w-8/12 bg-gray-50"
        :class="{ 'rounded-br-lg' : index == itemRows.length-1 }"
        type="text"
        readonly
        :value="row.amount"
      />
      <div
        v-if="currency.after_nr"
        class="w-4/12"
      >
        {{ currency.symbol }}
      </div>

      <div
        v-if="itemRows.length > 1"
        class="absolute top-0 right-0 p-2 pr-1 text-red-100 hover:text-red-600 cursor-pointer"
        @click="removeRow(index)"
      >
        <i class="fas fa-minus-circle"></i>
      </div>
    </div>

  </div>

  <div class="mt-4 flex md:mt-0 w-full justify-center">
    <span
      @click="addRow"
      class="rounded-lg p-2 px-4 md:px-2 md:py-0 md:rounded-t-none bg-il-purple-faded hover:bg-il-purple text-white cursor-pointer transition-all duration-200 ease-in-out"
    >
      <i class="fas fa-plus"></i>
    </span>
  </div>

</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import lodash from 'lodash';

  export default {
    props: [
      'accentColor',
      'currency',
      'rowValidation',
      'itemLabel',
      'quantityLabel',
      'rateLabel',
      'amountLabel',
      'itemRows'
    ],
    emits: [
      'update:itemLabel',
      'update:quantityLabel',
      'update:rateLabel',
      'update:amountLabel',
      'update:itemRows'
    ],
    data() {
      return {
        lastScreenWidth: 0
      }
    },
    created() {
      // Debounce should optimise the frequency with which the resizing is called
      window.addEventListener("resize", lodash.debounce(this.screenResized, 300));
    },
    unmounted() {
      window.removeEventListener("resize", this.screenResized);
    },
    methods: {
      screenResized(e) {
        const textAreas = document.getElementsByTagName('textarea');
        let that = this;
        if(
          (this.lastScreenWidth < 768 && screen.width >= 768)
          || (this.lastScreenWidth >= 768 && screen.width < 768)
        ) {
          this.$nextTick(function() {
            lodash.forEach(textAreas, function(ta) {
              if(!ta.classList.contains('hidden')) {
                that.autoResizeMX(ta,ta.getAttribute('data-height-step'));
              }
            });
          });
        }
        this.lastScreenWidth = screen.width;
      },
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
        // this.resizeTextAreasForMobile();
      },
      updateRowItem(prop,target,heightStep,index,key) {
        this.autoResizeMX(target,heightStep);
        let items = JSON.parse(JSON.stringify(this.itemRows));
        items[index][key] = target.value;
        this.$emit('update:'+prop,items);
        // this.resizeTextAreasForMobile();
      },
      quantityTypeSwitch(index) {
        let items = JSON.parse(JSON.stringify(this.itemRows));
        if(items[index]['isTime']) { // Switch to quantity
          items[index]['isTime'] = false;
          items[index]['quantity'] = 1;
          this.$emit('update:itemRows',items);
        } else { // Switch to time
          items[index]['isTime'] = true;
          this.$nextTick(function() {
            items[index]['quantity'] = '02:30';
            this.$emit('update:itemRows',items);
          });
        }
      },
      addRow() {
        let items = JSON.parse(JSON.stringify(this.itemRows));
        items.push({
          'item': 'New Item',
          'quantity': 1,
          'rate': 0,
          'isTime': false,
          'amount': 0,
          'perHourLabel': '/ hr'
        });
        this.$emit('update:itemRows',items);

        // Resize for mobile
        this.resizeTextAreasForMobile();
      },
      removeRow(index) {
        let items = JSON.parse(JSON.stringify(this.itemRows));
        if(items.length > 1) {
          lodash.pullAt(items, index);
          this.$emit('update:itemRows',items);
        }
      },
      resizeTextAreasForMobile() {
        if(screen.width < 768) {
          this.$nextTick(function() {
            const textAreas = document.getElementsByTagName('textarea');
            let that = this;
            lodash.forEach(textAreas, function(ta) {
              if(!ta.classList.contains('hidden')) {
                that.autoResizeMX(ta,ta.getAttribute('data-height-step'));
              }
            });
          });
        }
      }
    },
    computed: {
      ...mapGetters([

      ]),

    }
  }
</script>