<template>
  <div class="bg-gray-50 p-4 mt-4 rounded-lg w-full max-w-pdf flex flex-col">

    <div class="flex flex-wrap justify-start md:justify-end">

      <div
        v-if="!downloadLoading"
        @click="$parent.resetForm"
        class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-white text-il-purple hover:bg-il-purple hover:text-white cursor-pointer transition-all duration-200 ease-in-out"
      >
        <i class="fas fa-eraser"></i>
        <span class="ml-2">Reset</span>
      </div>

      <div
        v-if="!downloadLoading"
        @click="$parent.showPreview"
        class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-white text-il-purple hover:bg-il-purple hover:text-white cursor-pointer transition-all duration-200 ease-in-out"
      >
        <i class="fas fa-eye"></i>
        <span class="ml-2">Preview</span>
      </div>

      <div
        v-if="!downloadLoading"
        @click="$parent.download"
        class="w-full text-center md:w-auto mt-2 md:mt-0 md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium transition-all duration-200 ease-in-out bg-white text-il-purple hover:bg-il-purple hover:text-white cursor-pointer"
      >
        <div>
          <i class="fas fa-cloud-download-alt"></i>
          <span class="ml-2">download</span>
        </div>
      </div>

      <div
        v-if="downloadLoading"
        class="w-full text-center md:w-auto md:ml-2 uppercase text-sm px-4 py-2 rounded-lg border border-gray-300 font-medium bg-il-purple text-white"
      >
        <div>
          <span class="mr-2">Processing</span>
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
      </div>

    </div>

    <div
      class="text-sm mt-6 flex flex-col"
      v-if="invoiceUUID"
    >
      <div class="uppercase font-bold text-il-purple">
        temporary invoice id
        <input
          id="quick-invoice-id"
          @click="copyToClipboard('quick-invoice-id')"
          class="ml-2 text-black normal-case p-2 px-4 bg-white rounded-lg cursor-default w-80 text-center"
          :value="invoiceUUID"
          v-tippy="{ content: 'Click to copy'}"
          readonly
        />
      </div>

      <div class="mt-4 text-il-purple">
        Quick Invoices are stored for 10 minutes after downloading but you can also <span class="text-red-600 cursor-pointer underline" @click="$parent.deleteInvoice">Instantly Delete this Invoice</span>.
        <span v-if="deleteLoading"><i class="fas fa-circle-notch fa-spin text-red-600"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
  import { directive } from 'vue-tippy';

  export default {
    props: [
      'invoiceUUID',
      'deleteLoading',
      'downloadLoading'
    ],
    directives: {
      'v-tippy': directive,
    },
    methods: {
      copyToClipboard(elId) {
        this.copyToClipboardMX(elId);
        this.popSuccessMessageMX('Invoice ID copied to clipboard');
      }
    }
  }
</script>

