<template>

  <input
    class="w-full text-gray-400 rounded-lg uppercase text-xs p-1 font-bold outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
    type="text"
    :value="notesLabel"
    @input="updateValue('notesLabel', $event.target)"
  />
  <textarea
    class="w-full mt-1 overflow-hidden text-gray-600 outline-none p-1 rounded-lg resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
    rows='2'
    data-min-rows='2'
    data-height-step='24'
    :value="notesText"
    @input="updateValue('notesText', $event.target, 24)"
    placeholder="Additional notes, contract information, etc."
  >
  </textarea>

  <input
    class="w-full text-gray-400 rounded-lg uppercase text-xs p-1 font-bold mt-2 outline-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
    type="text"
    :value="termsLabel"
    @input="updateValue('termsLabel', $event.target)"
  />
  <textarea
    class="w-full mt-1 overflow-hidden text-gray-600 outline-none p-1 rounded-lg resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
    rows='2'
    data-min-rows='2'
    data-height-step='24'
    :value="termsText"
    @input="updateValue('termsText', $event.target, 24)"
    placeholder="Payment details, detailed payment instructions, etc."
  >
  </textarea>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    props: [
      'notesLabel',
      'notesText',
      'termsLabel',
      'termsText'
    ],
    emits: [
      'update:notesLabel',
      'update:notesText',
      'update:termsLabel',
      'update:termsText'
    ],
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([

      ]),
      updateValue(prop,target,heightStep=null) {
        if(heightStep) {
          this.autoResizeMX(target,heightStep);
        }
        this.$emit('update:'+prop,target.value);
      },
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>

<style>

</style>