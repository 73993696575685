<template>

  <textarea
    ref="nameRef"
    class="rounded-lg text-lg outline-none mt-2 p-2 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
    rows='1'
    data-min-rows='1'
    data-height-step='28'
    :value="name"
    @input="updateValue('name', $event.target, 28)"
    placeholder="From"
  >
  </textarea>

  <textarea
    ref="addressRef"
    class="rounded-lg outline-none text-gray-400 mt-1 p-2 resize-none transition-all duration-200 ease-in-out border border-gray-200 focus:border-il-purple"
    rows='2'
    data-min-rows='2'
    data-height-step='24'
    :value="address"
    @input="updateValue('address', $event.target, 24)"
    placeholder="Contact Details"
  >
  </textarea>

</template>


<script>
  import { mapGetters, mapMutations } from 'vuex';
  export default {
    props: ['name', 'address'],
    emits: [
      'update:name',
      'update:address'
    ],
    mounted() {
      this.$nextTick(function() {
        this.autoResizeMX(this.$refs.nameRef,28);
        this.autoResizeMX(this.$refs.addressRef,24);
      });
    },
    data() {
      return {

      }
    },
    methods: {
      ...mapMutations([
        'setFromNameX',
        'setFromAddressX'
      ]),
      updateValue(prop,target,heightStep) {
        this.autoResizeMX(target,heightStep);
        this.$emit('update:'+prop,target.value);
      }
    },
    computed: {
      ...mapGetters([

      ]),
    }
  }
</script>